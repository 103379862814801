import React, {useState} from "react";
import {useForm} from "@formspree/react";
import {CharacterOptions, CharacterState} from "./utils/types";
import {Color, hexToRgb, Solver} from "./utils/color";
import './character.css';
import Dropdown, {people} from "./Dropdown";
import {Tooltip} from "@material-tailwind/react";
import uuid from "react-uuid";

const randomId = uuid();
const activeStyle = "inline-block p-4 text-white bg-pink-400 rounded-lg active";
const notActiveStyle = "inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300";

const questionIdToString = (id: string) => {
    return {
        'question-1': 'first',
        'question-2': 'second',
        'question-3': 'third',
        'question-4': 'fourth',
        'question-5': 'fifth',
    }[id];
}

function QandA({ id }: { id: string }) {
    return (
        <>
            <div className="grid grid-cols-10 gap-4 border-t border-rose-200 pt-4 pb-6">
                <span className="col-span-3 text-sm text-gray-500">Enter the {questionIdToString(id)} question</span>
                <CustomInput id={id} col="10" width="full" />
                <span className="col-span-3 mt-2 text-sm text-gray-500">Check the correct answer</span>
                <span className="col-span-7 mt-2 text-sm text-gray-500">Enter the answers</span>
                {[1, 2, 3, 4].map((value) => {
                    return (
                        <div className="col-span-10 flex justify-end w-full gap-4">
                            <input required type="radio" value={`answer-${value}`} className="col-span-3 m-auto focus:ring-pink-300 checked:focus:bg-pink-400 text-pink-400" name={`answer-${id}`}/>
                            <input required id={`${id}-answer-${value}`} name={`${id}-answer-${value}`} type="text" className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-pink-400 sm:text-sm sm:leading-6`}/>
                        </div>
                    );
                })}
            </div>
        </>
    );
}

function ContactForm() {
    const [characterTab, setCharacterTab] = React.useState(0);

    const [selectedEnemy, setSelectedEnemy] = useState(people[3]);

    const [itemIndex, setItemIndex] = useState<CharacterState[]>([{
        body: 1,
        hair: 1,
        mouth: 1,
        top: 1,
        bottom: 1,
    },{
        body: 1,
        hair: 1,
        mouth: 1,
        top: 1,
        bottom: 1,
    }]);

    const [color, setColor] = useState<CharacterState[]>([{
        body: 'invert(98%) sepia(13%) saturate(4795%) hue-rotate(301deg) brightness(81%) contrast(87%)',
        hair: 'invert(28%) sepia(40%) saturate(464%) hue-rotate(351deg) brightness(93%) contrast(93%)',
        eyes: 'invert(81%) sepia(9%) saturate(1790%) hue-rotate(158deg) brightness(98%) contrast(91%)',
        top: 'invert(27%) sepia(68%) saturate(3457%) hue-rotate(344deg) brightness(104%) contrast(88%)',
        bottom: 'invert(32%) sepia(93%) saturate(293%) hue-rotate(164deg) brightness(100%) contrast(95%)',
        mouth: 'invert(32%) sepia(93%) saturate(293%) hue-rotate(164deg) brightness(100%) contrast(95%)',
    },{
        body: 'invert(98%) sepia(13%) saturate(4795%) hue-rotate(301deg) brightness(81%) contrast(87%)',
        hair: 'invert(28%) sepia(40%) saturate(464%) hue-rotate(351deg) brightness(93%) contrast(93%)',
        eyes: 'invert(81%) sepia(9%) saturate(1790%) hue-rotate(158deg) brightness(98%) contrast(91%)',
        top: 'invert(27%) sepia(68%) saturate(3457%) hue-rotate(344deg) brightness(104%) contrast(88%)',
        bottom: 'invert(32%) sepia(93%) saturate(293%) hue-rotate(164deg) brightness(100%) contrast(95%)',
        mouth: 'invert(32%) sepia(93%) saturate(293%) hue-rotate(164deg) brightness(100%) contrast(95%)',
    }]);

    const [hex, setHex] = useState<CharacterState[]>([{
        body: '#c5b26d',
        hair: '#60492F',
        eyes: '#81D3EE',
        top: '#EE3A3A',
        bottom: '#376C95',
        mouth: '#376C95',
    },{
        body: '#c5b26d',
        hair: '#60492F',
        eyes: '#81D3EE',
        top: '#EE3A3A',
        bottom: '#376C95',
        mouth: '#376C95',
    }]);

    const [state, handleSubmit] = useForm('xzbneayb', {
        data: {
            id: randomId,
            body_partner: itemIndex[0].body + '-' + hex[0].body,
            hair_partner: itemIndex[0].hair + '-' + hex[0].hair,
            eyes_partner: '-' + hex[0].eyes,
            mouth_partner: itemIndex[0].mouth + '-' + hex[0].mouth,
            top_partner: itemIndex[0].top + '-' + hex[0].top,
            bottom_partner: itemIndex[0].bottom + '-' + hex[0].bottom,
            body_yours: itemIndex[1].body + '-' + hex[1].body,
            hair_yours: itemIndex[1].hair + '-' + hex[1].hair,
            eyes_yours: '-' + hex[1].eyes,
            mouth_yours: itemIndex[1].mouth + '-' + hex[1].mouth,
            top_yours: itemIndex[1].top + '-' + hex[1].top,
            bottom_yours: itemIndex[1].bottom + '-' + hex[1].bottom,
            enemy: selectedEnemy.name,
        },
    });

    if (state.succeeded) {
        return (<div className="text-center m-8 justify-center">
            <p>Your customization id: <b>{randomId}</b></p>
            <br/>
            <p>Enter the customization ID in the "Customization ID" field on the product page.</p>
            <br/>
            <div>
                <img src={require('./assets/customId.png')} alt="" className="w-80 block mx-auto"/>
            </div>
        </div>);
    }

    const characterObject = {
        your: {
            title: 'Customize your avatar',
            description: 'Now it\'s your turn! Create an avatar that\'s all about you. Choose your body type, clothes, hairstyle, and more. Pick the colors that match your style perfectly.',
        },
        partner: {
            title: 'Customize your partner\'s avatar',
            description: 'Hey there! Ready to create a virtual version of your partner? Let\'s get started! Below, you\'ll find options to personalize their avatar to match their unique look and style. Have fun choosing from different body types, clothes, hairstyles, and more. Don\'t forget to select colors that reflect their personality and wardrobe preferences.',
        },
    }

    return (
        <form onSubmit={handleSubmit}>
            <div
                className="text-sm font-medium text-center dark:text-gray-400 dark:border-gray-700 my-4">
                <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl my-4">Choose character to customize</h2>
                <ul className="flex flex-wrap justify-center -mb-px">
                    <li className="me-2">
                        <a href="#"
                           onClick={e => {
                               e.preventDefault();
                               setCharacterTab(0);
                           }}
                           className={characterTab === 0 ? activeStyle : notActiveStyle}>
                            Your partner's character
                        </a>
                    </li>
                    <li className="me-2">
                        <a href="#"
                           onClick={e => {
                               e.preventDefault();
                               setCharacterTab(1);
                           }}
                           className={characterTab === 1 ? activeStyle : notActiveStyle}>
                            Your character
                        </a>
                    </li>
                </ul>
            </div>
            <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-8 px-4 pb-24 sm:px-6 sm:pb-32 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
                <div className="block lg:hidden">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                        { characterTab === 0 ? characterObject.partner.title : characterObject.your.title }
                    </h2>
                    <p className="mt-4 text-gray-500">
                        { characterTab === 0 ? characterObject.partner.description : characterObject.your.description }
                    </p>
                </div>
                <div className="items-center">
                    <CharacterInput value={itemIndex} filter={color} characterTab={characterTab} />
                </div>
                <div>
                    <div className="mt-16 hidden lg:block">
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                            { characterTab === 0 ? characterObject.partner.title : characterObject.your.title }
                        </h2>
                        <p className="mt-4 text-gray-500">
                            { characterTab === 0 ? characterObject.partner.description : characterObject.your.description }
                        </p>
                    </div>

                    <LoopingValue
                        itemIndex={itemIndex}
                        setItemIndex={setItemIndex}
                        colorValue={color}
                        setColor={setColor}
                        hex={hex}
                        setHex={setHex}
                        characterTab={characterTab}
                    />
                </div>
            </div>
            <div className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center mb-8">
                <h2>Custom your game</h2>
            </div>
            <div className="grid grid-cols-12 gap-4">
                <div className="col-span-12">
                    <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-12 lg:col-span-6 rounded p-2">
                            <h2>Tell us about you</h2>
                            <div className="grid grid-cols-9 gap-4 border-t border-rose-200 pt-4 pb-6">
                                <span className="col-span-3 text-sm text-gray-500">Your name</span>
                                <span className="col-span-3 text-sm text-gray-500">Partner's name</span>
                                <span className="col-span-3 text-sm text-gray-500">Nickname Your Partner Calls You</span>
                                <CustomInput id="name" col="3" width="full" />
                                <CustomInput id="partnerName" col="3" width="full"  />
                                <CustomInput id="nickname" col="3" width="full"  />
                                <span className="col-span-9 text-sm text-gray-500">Partner's Favorite Food</span>
                                <CustomInput id="favourite-food" col="9" width="full" />
                                <span className="col-span-9 text-sm text-gray-500">What better way to show love than with compliments? Tell us the sweet things you'd love to say to your partner (e.g., "You always know how to make me smile!"): </span>
                                {[1, 2, 3, 4].map((value) => {
                                    return (
                                        <>
                                            <div className="col-span-9 flex justify-end w-full gap-4">
                                                <span className="col-span-3 m-auto text-sm text-gray-500">{value}</span>
                                                <input required id={`compliment-${value}`} name={`compliment-${value}`} type="text" placeholder="You always know how to make me smile!" className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-pink-400 sm:text-sm sm:leading-6`}/>
                                            </div>
                                        </>
                                    );
                                })}
                            </div>
                            <h2>Pick Your Partner's Enemy</h2>
                            <div className="grid grid-cols-9 gap-4 border-t border-rose-200 pt-4 pb-6">
                                <span className="col-span-9 text-sm text-gray-500">Choose the enemy your partner can't stand. They'll kidnap you, and your partner will fight to rescue you!</span>
                                <EnemyTooltip />
                                <div className="col-span-9">
                                    <Dropdown selected={selectedEnemy} setSelected={setSelectedEnemy} />
                                </div>
                                <span className="col-span-9 text-sm text-gray-500">
                                    What would your partner say to roast this enemy? Share their words here! <br/>
                                    Insult Your Partner Would Say:</span>
                                <CustomInput id="insult" col="9" width="full" />
                                <span className="col-span-9 text-sm text-gray-500">Imagine your partner in battle mode! Picture the attacks they'd use against the enemy. These could be playful moves they'd typically use on you, like bursts of cuteness, crying with no reason. <br />
                                    Get creative and share four of these unexpected tactics below!</span>
                                {[1, 2, 3, 4].map((value) => {
                                    return (
                                        <>
                                            <div className="col-span-9 flex justify-end w-full gap-4">
                                                <span className="col-span-3 m-auto text-sm text-gray-500">{value}</span>
                                                <input required id={`attack-${value}`} name={`attack-${value}`} type="text" className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-pink-400 sm:text-sm sm:leading-6`}/>
                                            </div>
                                        </>
                                    );
                                })}
                            </div>
                            <div className="grid grid-cols-9 gap-4 border-t border-rose-200 pt-4 pb-6">
                                <span className="col-span-9 text-sm text-gray-500">Enter the final message</span>
                                <span className="col-span-9 text-sm text-gray-500">This message will show after your partner complete the game.</span>
                                <CustomInput id="final-message" col="9" width="full" />
                            </div>
                        </div>
                        <div className="col-span-12 lg:col-span-6 rounded p-2">
                            <h2>Questions</h2>
                            <span className="col-span-3 text-sm text-gray-500">Your partner needs to answer a questions about You or your relationship correctly to rescue you. Provide the question along with four possible answers, check the correct one with checkbox on the left.</span>
                            <QandA id="question-1"/>
                            <QandA id="question-2"/>
                            <QandA id="question-3"/>
                            <QandA id="question-4"/>
                            <QandA id="question-5"/>
                        </div>
                    </div>
                    <div className="flex justify-end">
                        <button
                            type="submit"
                            disabled={state.submitting}
                            className="rounded-md bg-pink-400 px-3 py-2 m-4 text-sm font-semibold text-white shadow-sm hover:bg-pink-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-400"
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
}

function EnemyTooltip() {
    return (
        <Tooltip
            content={
                <div className="bg-gray-400 p-4 rounded w-80">
                    <span>
                        Your partner will have to rescue you from someone they absolutely can't stand. Will it be an opp from the hood, or other person from our characters list that your partner wouldn't like if they were real? Pick from a list of quirky characters that'll make your partner squirm, but remember, it's all in good fun! And hey, make sure not to choose someone your partner actually ma like - we've got some special dialogues lined up that'll have haters laughing and fans fuming
                    </span>
                </div>
            }
            placement="right"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
                className="h-5 w-5 cursor-pointer text-blue-gray-500"
            >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                />
            </svg>
        </Tooltip>
    );
}

function LoopingValue({
                          itemIndex,
                          colorValue,
                          setItemIndex,
                          setColor,
                          hex,
                          setHex,
                          characterTab
                      }: {
    itemIndex: CharacterState[];
    colorValue: CharacterState[];
    setItemIndex: React.Dispatch<React.SetStateAction<CharacterState[]>>;
    setColor: React.Dispatch<React.SetStateAction<CharacterState[]>>;
    hex: CharacterState[];
    setHex: React.Dispatch<React.SetStateAction<CharacterState[]>>;
    characterTab: number;
}) {
    const [optionTab, setOptionTab] = React.useState(0);

    const decrementValue = (key: CharacterOptions, maxIndex: number) => {
        setItemIndex(prevCharacters => prevCharacters.map((itemIndex, id) => {
            if (id === characterTab) {
                return {
                    ...itemIndex,
                    [key]: itemIndex[key] === 1 ? maxIndex : itemIndex[key] as number - 1,
                };
            }
            return itemIndex;
        }));
    };

    const incrementValue = (key: CharacterOptions, maxIndex: number) => {
        setItemIndex(prevCharacters => prevCharacters.map((itemIndex, id) => {
            if (id === characterTab) {
                return {
                    ...itemIndex,
                    [key]: itemIndex[key] === maxIndex ? 1 : itemIndex[key] as number + 1,
                };
            }
            return itemIndex;
        }));
    };

    const testClick = (key: CharacterOptions, value: any) => {
        const rgb = hexToRgb(value.target.value);

        // @ts-ignore
        const color = new Color(rgb[0], rgb[1], rgb[2]);
        const solver = new Solver(color);
        const result = solver.solve();

        setColor(prevCharacters => prevCharacters.map((colorValue, id) => {
            if (id === characterTab) {
                return {
                    ...colorValue,
                    [key]: result.filter,
                };
            }
            return colorValue;
        }));

        setHex(prevCharacters => prevCharacters.map((hex, id) => {
            if (id === characterTab) {
                return {
                    ...hex,
                    [key]: value.target.value,
                };
            }
            return hex;
        }));
    };

    const optionHtml = [
        <div className="border-t border-rose-200 pt-4">
            <dt className="font-medium text-gray-900">Body</dt>
            <dd className="mt-2 text-sm text-gray-500 grid grid-cols-2 gap-4">
                <div>
                    <span>Choose type</span>
                    <br/>
                    <div className="text-2xl mt-2">
                        <button type="button" onClick={() => decrementValue('body', 3)}>←</button>
                        <span> {itemIndex[characterTab].body} </span>
                        <button type="button" onClick={() => incrementValue('body', 3)}>→</button>
                    </div>
                </div>
                <div>
                    <span>Choose color</span>
                    <br/>
                    <input
                        type="color"
                        className="mt-2"
                        value={hex[characterTab].body}
                        onChange={(color) => testClick('body', color)}
                    />
                </div>
            </dd>
        </div>,
        <div className="border-t border-rose-200 pt-4">
            <dt className="font-medium text-gray-900">Hair</dt>
            <dd className="mt-2 text-sm text-gray-500 grid grid-cols-2 gap-4">
                <div>
                    <span>Choose type</span>
                    <br/>
                    <div className="text-2xl mt-2">
                        <button type="button" onClick={() => decrementValue('hair', 19)}>←</button>
                        <span> {itemIndex[characterTab].hair} </span>
                        <button type="button" onClick={() => incrementValue('hair', 19)}>→</button>
                    </div>
                </div>
                <div>
                    <span>Choose color</span>
                    <br/>
                    <input
                        type="color"
                        className="mt-2"
                        value={hex[characterTab].hair}
                        onChange={(color) => testClick('hair', color)}
                    />
                </div>
            </dd>
        </div>,
        <div className="border-t border-rose-200 pt-4">
            <dt className="font-medium text-gray-900">Eyes</dt>
            <dd className="mt-2 text-sm text-gray-500 grid grid-cols-2 gap-4">
                <div>
                    <span>Choose color</span>
                    <br/>
                    <input
                        type="color"
                        className="mt-2"
                        value={hex[characterTab].eyes}
                        onChange={(color) => testClick('eyes', color)}
                    />
                </div>
            </dd>
        </div>,
        <div className="border-t border-rose-200 pt-4">
            <dt className="font-medium text-gray-900">Mouth</dt>
            <dd className="mt-2 text-sm text-gray-500 grid grid-cols-2 gap-4">
                <div>
                    <span>Choose type</span>
                    <br/>
                    <div className="text-2xl mt-2">
                        <button type="button" onClick={() => decrementValue('mouth', 14)}>←</button>
                        <span> {itemIndex[characterTab].mouth} </span>
                        <button type="button" onClick={() => incrementValue('mouth', 14)}>→</button>
                    </div>
                </div>
                <div>
                    <span>Choose color</span>
                    <br/>
                    <input
                        type="color"
                        className="mt-2"
                        value={hex[characterTab].mouth}
                        onChange={(color) => testClick('mouth', color)}
                    />
                </div>
            </dd>
        </div>,
        <div className="border-t border-rose-200 pt-4">
            <dt className="font-medium text-gray-900">Top</dt>
            <dd className="mt-2 text-sm text-gray-500 grid grid-cols-2 gap-4">
                <div>
                    <span>Choose type</span>
                    <br/>
                    <div className="text-2xl mt-2">
                        <button type="button" onClick={() => decrementValue('top', 10)}>←</button>
                        <span> {itemIndex[characterTab].top} </span>
                        <button type="button" onClick={() => incrementValue('top', 10)}>→</button>
                    </div>
                </div>
                <div>
                    <span>Choose color</span>
                    <br/>
                    <input
                        type="color"
                        className="mt-2"
                        value={hex[characterTab].top}
                        onChange={(color) => testClick('top', color)}
                    />
                </div>
            </dd>
        </div>,
        <div className="border-t border-rose-200 pt-4">
            <dt className="font-medium text-gray-900">Bottom</dt>
            <dd className="mt-2 text-sm text-gray-500 grid grid-cols-2 gap-4">
                <div>
                    <span>Choose type</span>
                    <br/>
                    <div className="text-2xl mt-2">
                        <button type="button" onClick={() => decrementValue('bottom', 10)}>←</button>
                        <span> {itemIndex[characterTab].bottom} </span>
                        <button type="button" onClick={() => incrementValue('bottom', 10)}>→</button>
                    </div>
                </div>
                <div>
                    <span>Choose color</span>
                    <br/>
                    <input
                        type="color"
                        className="mt-2"
                        value={hex[characterTab].bottom}
                        onChange={(color) => testClick('bottom', color)}
                    />
                </div>
            </dd>
        </div>
    ]

    const optionTitle = [
        'Body',
        'Hair',
        'Eyes',
        'Mouth',
        'Top',
        'Bottom'
    ]

    return (
        <>
            <div
                className="block lg:hidden text-sm font-medium text-center text-gray-500 dark:text-gray-400 dark:border-gray-700">
                <ul className="flex flex-wrap justify-center -mb-px">
                    {[0, 1, 2, 3, 4, 5].map((value) => {
                        return (
                            <>
                                <li className="me-2">
                                    <a href="#"
                                       onClick={e => {
                                           e.preventDefault();
                                           setOptionTab(value);
                                       }}
                                       className={optionTab === value ? activeStyle : notActiveStyle}>
                                        {optionTitle[value]}
                                    </a>
                                </li>
                            </>
                        );
                    })}
                </ul>
            </div>
            <dl className="mt-4 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-2 sm:gap-y-8 lg:gap-x-8 lg:hidden">
                {optionHtml[optionTab]}
            </dl>
            <dl className="mt-16 hidden grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-2 sm:gap-y-8 lg:gap-x-8 lg:grid">
                {optionHtml[0]}
                {optionHtml[1]}
                {optionHtml[2]}
                {optionHtml[3]}
                {optionHtml[4]}
                {optionHtml[5]}
            </dl>
        </>
    );
}

function CharacterInput({
                            value,
                            filter,
                            characterTab
                        }: {
    value: CharacterState[];
    filter: CharacterState[];
    characterTab: number;
}) {
    const files = {
        body: {
            fill: '',
            border: '',
        },
        hair: {
            fill: '',
            border: '',
        },
        mouth: {
            fill: '',
            border: '',
        },
        top: {
            fill: '',
            border: '',
        },
        bottom: {
            fill: '',
            border: '',
        },
    };

    switch (value[characterTab].body) {
        case 1:
            files.body.fill = require('./assets/body/1-fill.png');
            files.body.border = require('./assets/body/1-border.png');
            switch (value[characterTab].top) {
                case 1:
                    files.top.fill = require('./assets/top/1/1-fill.png');
                    files.top.border = require('./assets/top/1/1-border.png');
                    break;
                case 2:
                    files.top.fill = require('./assets/top/1/2-fill.png');
                    files.top.border = require('./assets/top/1/2-border.png');
                    break;
                case 3:
                    files.top.fill = require('./assets/top/1/3-fill.png');
                    files.top.border = require('./assets/top/1/3-border.png');
                    break;
                case 4:
                    files.top.fill = require('./assets/top/1/4-fill.png');
                    files.top.border = require('./assets/top/1/4-border.png');
                    break;
                case 5:
                    files.top.fill = require('./assets/top/1/5-fill.png');
                    files.top.border = require('./assets/top/1/5-border.png');
                    break;
                case 6:
                    files.top.fill = require('./assets/top/1/6-fill.png');
                    files.top.border = require('./assets/top/1/6-border.png');
                    break;
                case 7:
                    files.top.fill = require('./assets/top/1/7-fill.png');
                    files.top.border = require('./assets/top/1/7-border.png');
                    break;
                case 8:
                    files.top.fill = require('./assets/top/1/8-fill.png');
                    files.top.border = require('./assets/top/1/8-border.png');
                    break;
                case 9:
                    files.top.fill = require('./assets/top/1/9-fill.png');
                    files.top.border = require('./assets/top/1/9-border.png');
                    break;
                case 10:
                    files.top.fill = require('./assets/top/1/10-fill.png');
                    files.top.border = require('./assets/top/1/10-border.png');
                    break;
            }
            switch (value[characterTab].bottom) {
                case 1:
                    files.bottom.fill = require('./assets/bottom/1/1-fill.png');
                    files.bottom.border = require('./assets/bottom/1/1-border.png');
                    break;
                case 2:
                    files.bottom.fill = require('./assets/bottom/1/2-fill.png');
                    files.bottom.border = require('./assets/bottom/1/2-border.png');
                    break;
                case 3:
                    files.bottom.fill = require('./assets/bottom/1/3-fill.png');
                    files.bottom.border = require('./assets/bottom/1/3-border.png');
                    break;
                case 4:
                    files.bottom.fill = require('./assets/bottom/1/4-fill.png');
                    files.bottom.border = require('./assets/bottom/1/4-border.png');
                    break;
                case 5:
                    files.bottom.fill = require('./assets/bottom/1/5-fill.png');
                    files.bottom.border = require('./assets/bottom/1/5-border.png');
                    break;
                case 6:
                    files.bottom.fill = require('./assets/bottom/1/6-fill.png');
                    files.bottom.border = require('./assets/bottom/1/6-border.png');
                    break;
                case 7:
                    files.bottom.fill = require('./assets/bottom/1/7-fill.png');
                    files.bottom.border = require('./assets/bottom/1/7-border.png');
                    break;
                case 8:
                    files.bottom.fill = require('./assets/bottom/1/8-fill.png');
                    files.bottom.border = require('./assets/bottom/1/8-border.png');
                    break;
                case 9:
                    files.bottom.fill = require('./assets/bottom/1/9-fill.png');
                    files.bottom.border = require('./assets/bottom/1/9-border.png');
                    break;
                case 10:
                    files.bottom.fill = require('./assets/bottom/1/10-fill.png');
                    files.bottom.border = require('./assets/bottom/1/10-border.png');
                    break;
            }
            break;
        case 2:
            files.body.fill = require('./assets/body/2-fill.png');
            files.body.border = require('./assets/body/2-border.png');
            switch (value[characterTab].top) {
                case 1:
                    files.top.fill = require('./assets/top/2/1-fill.png');
                    files.top.border = require('./assets/top/2/1-border.png');
                    break;
                case 2:
                    files.top.fill = require('./assets/top/2/2-fill.png');
                    files.top.border = require('./assets/top/2/2-border.png');
                    break;
                case 3:
                    files.top.fill = require('./assets/top/2/3-fill.png');
                    files.top.border = require('./assets/top/2/3-border.png');
                    break;
                case 4:
                    files.top.fill = require('./assets/top/2/4-fill.png');
                    files.top.border = require('./assets/top/2/4-border.png');
                    break;
                case 5:
                    files.top.fill = require('./assets/top/2/5-fill.png');
                    files.top.border = require('./assets/top/2/5-border.png');
                    break;
                case 6:
                    files.top.fill = require('./assets/top/2/6-fill.png');
                    files.top.border = require('./assets/top/2/6-border.png');
                    break;
                case 7:
                    files.top.fill = require('./assets/top/2/7-fill.png');
                    files.top.border = require('./assets/top/2/7-border.png');
                    break;
                case 8:
                    files.top.fill = require('./assets/top/2/8-fill.png');
                    files.top.border = require('./assets/top/2/8-border.png');
                    break;
                case 9:
                    files.top.fill = require('./assets/top/2/9-fill.png');
                    files.top.border = require('./assets/top/2/9-border.png');
                    break;
                case 10:
                    files.top.fill = require('./assets/top/2/10-fill.png');
                    files.top.border = require('./assets/top/2/10-border.png');
                    break;
            }
            switch (value[characterTab].bottom) {
                case 1:
                    files.bottom.fill = require('./assets/bottom/2/1-fill.png');
                    files.bottom.border = require('./assets/bottom/2/1-border.png');
                    break;
                case 2:
                    files.bottom.fill = require('./assets/bottom/2/2-fill.png');
                    files.bottom.border = require('./assets/bottom/2/2-border.png');
                    break;
                case 3:
                    files.bottom.fill = require('./assets/bottom/2/3-fill.png');
                    files.bottom.border = require('./assets/bottom/2/3-border.png');
                    break;
                case 4:
                    files.bottom.fill = require('./assets/bottom/2/4-fill.png');
                    files.bottom.border = require('./assets/bottom/2/4-border.png');
                    break;
                case 5:
                    files.bottom.fill = require('./assets/bottom/2/5-fill.png');
                    files.bottom.border = require('./assets/bottom/2/5-border.png');
                    break;
                case 6:
                    files.bottom.fill = require('./assets/bottom/2/6-fill.png');
                    files.bottom.border = require('./assets/bottom/2/6-border.png');
                    break;
                case 7:
                    files.bottom.fill = require('./assets/bottom/2/7-fill.png');
                    files.bottom.border = require('./assets/bottom/2/7-border.png');
                    break;
                case 8:
                    files.bottom.fill = require('./assets/bottom/2/8-fill.png');
                    files.bottom.border = require('./assets/bottom/2/8-border.png');
                    break;
                case 9:
                    files.bottom.fill = require('./assets/bottom/2/9-fill.png');
                    files.bottom.border = require('./assets/bottom/2/9-border.png');
                    break;
                case 10:
                    files.bottom.fill = require('./assets/bottom/2/10-fill.png');
                    files.bottom.border = require('./assets/bottom/2/10-border.png');
                    break;
            }
            break;
        case 3:
            files.body.fill = require('./assets/body/3-fill.png');
            files.body.border = require('./assets/body/3-border.png');
            switch (value[characterTab].top) {
                case 1:
                    files.top.fill = require('./assets/top/3/1-fill.png');
                    files.top.border = require('./assets/top/3/1-border.png');
                    break;
                case 2:
                    files.top.fill = require('./assets/top/3/2-fill.png');
                    files.top.border = require('./assets/top/3/2-border.png');
                    break;
                case 3:
                    files.top.fill = require('./assets/top/3/3-fill.png');
                    files.top.border = require('./assets/top/3/3-border.png');
                    break;
                case 4:
                    files.top.fill = require('./assets/top/3/4-fill.png');
                    files.top.border = require('./assets/top/3/4-border.png');
                    break;
                case 5:
                    files.top.fill = require('./assets/top/3/5-fill.png');
                    files.top.border = require('./assets/top/3/5-border.png');
                    break;
                case 6:
                    files.top.fill = require('./assets/top/3/6-fill.png');
                    files.top.border = require('./assets/top/3/6-border.png');
                    break;
                case 7:
                    files.top.fill = require('./assets/top/3/7-fill.png');
                    files.top.border = require('./assets/top/3/7-border.png');
                    break;
                case 8:
                    files.top.fill = require('./assets/top/3/8-fill.png');
                    files.top.border = require('./assets/top/3/8-border.png');
                    break;
                case 9:
                    files.top.fill = require('./assets/top/3/9-fill.png');
                    files.top.border = require('./assets/top/3/9-border.png');
                    break;
                case 10:
                    files.top.fill = require('./assets/top/3/10-fill.png');
                    files.top.border = require('./assets/top/3/10-border.png');
                    break;
            }
            switch (value[characterTab].bottom) {
                case 1:
                    files.bottom.fill = require('./assets/bottom/3/1-fill.png');
                    files.bottom.border = require('./assets/bottom/3/1-border.png');
                    break;
                case 2:
                    files.bottom.fill = require('./assets/bottom/3/2-fill.png');
                    files.bottom.border = require('./assets/bottom/3/2-border.png');
                    break;
                case 3:
                    files.bottom.fill = require('./assets/bottom/3/3-fill.png');
                    files.bottom.border = require('./assets/bottom/3/3-border.png');
                    break;
                case 4:
                    files.bottom.fill = require('./assets/bottom/3/4-fill.png');
                    files.bottom.border = require('./assets/bottom/3/4-border.png');
                    break;
                case 5:
                    files.bottom.fill = require('./assets/bottom/3/5-fill.png');
                    files.bottom.border = require('./assets/bottom/3/5-border.png');
                    break;
                case 6:
                    files.bottom.fill = require('./assets/bottom/3/6-fill.png');
                    files.bottom.border = require('./assets/bottom/3/6-border.png');
                    break;
                case 7:
                    files.bottom.fill = require('./assets/bottom/3/7-fill.png');
                    files.bottom.border = require('./assets/bottom/3/7-border.png');
                    break;
                case 8:
                    files.bottom.fill = require('./assets/bottom/3/8-fill.png');
                    files.bottom.border = require('./assets/bottom/3/8-border.png');
                    break;
                case 9:
                    files.bottom.fill = require('./assets/bottom/3/9-fill.png');
                    files.bottom.border = require('./assets/bottom/3/9-border.png');
                    break;
                case 10:
                    files.bottom.fill = require('./assets/bottom/3/10-fill.png');
                    files.bottom.border = require('./assets/bottom/3/10-border.png');
                    break;
            }
            break;
    }

    switch (value[characterTab].hair) {
        case 1:
            files.hair.fill = require('./assets/hair/1-fill.png');
            files.hair.border = require('./assets/hair/1-border.png');
            break;
        case 2:
            files.hair.fill = require('./assets/hair/2-fill.png');
            files.hair.border = require('./assets/hair/2-border.png');
            break;
        case 3:
            files.hair.fill = require('./assets/hair/3-fill.png');
            files.hair.border = require('./assets/hair/3-border.png');
            break;
        case 4:
            files.hair.fill = require('./assets/hair/4-fill.png');
            files.hair.border = require('./assets/hair/4-border.png');
            break;
        case 5:
            files.hair.fill = require('./assets/hair/5-fill.png');
            files.hair.border = require('./assets/hair/5-border.png');
            break;
        case 6:
            files.hair.fill = require('./assets/hair/6-fill.png');
            files.hair.border = require('./assets/hair/6-border.png');
            break;
        case 7:
            files.hair.fill = require('./assets/hair/7-fill.png');
            files.hair.border = require('./assets/hair/7-border.png');
            break;
        case 8:
            files.hair.fill = require('./assets/hair/8-fill.png');
            files.hair.border = require('./assets/hair/8-border.png');
            break;
        case 9:
            files.hair.fill = require('./assets/hair/9-fill.png');
            files.hair.border = require('./assets/hair/9-border.png');
            break;
        case 10:
            files.hair.fill = require('./assets/hair/10-fill.png');
            files.hair.border = require('./assets/hair/10-border.png');
            break;
        case 11:
            files.hair.fill = require('./assets/hair/11-fill.png');
            files.hair.border = require('./assets/hair/11-border.png');
            break;
        case 12:
            files.hair.fill = require('./assets/hair/12-fill.png');
            files.hair.border = require('./assets/hair/12-border.png');
            break;
        case 13:
            files.hair.fill = require('./assets/hair/13-fill.png');
            files.hair.border = require('./assets/hair/13-border.png');
            break;
        case 14:
            files.hair.fill = require('./assets/hair/14-fill.png');
            files.hair.border = require('./assets/hair/14-border.png');
            break;
        case 15:
            files.hair.fill = require('./assets/hair/15-fill.png');
            files.hair.border = require('./assets/hair/15-border.png');
            break;
        case 16:
            files.hair.fill = require('./assets/hair/16-fill.png');
            files.hair.border = require('./assets/hair/16-border.png');
            break;
        case 17:
            files.hair.fill = require('./assets/hair/17-fill.png');
            files.hair.border = require('./assets/hair/17-border.png');
            break;
        case 18:
            files.hair.fill = require('./assets/hair/18-fill.png');
            files.hair.border = require('./assets/hair/18-border.png');
            break;
        case 19:
            files.hair.fill = require('./assets/hair/19-fill.png');
            files.hair.border = require('./assets/hair/19-border.png');
            break;
    }

    switch (value[characterTab].mouth) {
        case 1:
            files.mouth.border = require('./assets/mouth/1-border.png');
            files.mouth.fill = require('./assets/mouth/1-fill.png');
            break;
        case 2:
            files.mouth.border = require('./assets/mouth/2-border.png');
            files.mouth.fill = require('./assets/mouth/2-fill.png');
            break;
        case 3:
            files.mouth.border = require('./assets/mouth/3-border.png');
            files.mouth.fill = require('./assets/mouth/3-fill.png');
            break;
        case 4:
            files.mouth.border = require('./assets/mouth/4-border.png');
            files.mouth.fill = require('./assets/mouth/4-fill.png');
            break;
        case 5:
            files.mouth.border = require('./assets/mouth/5-border.png');
            files.mouth.fill = require('./assets/mouth/5-fill.png');
            break;
        case 6:
            files.mouth.border = require('./assets/mouth/6-border.png');
            files.mouth.fill = require('./assets/mouth/6-fill.png');
            break;
        case 7:
            files.mouth.border = require('./assets/mouth/7-border.png');
            files.mouth.fill = require('./assets/mouth/7-fill.png');
            break;
        case 8:
            files.mouth.border = require('./assets/mouth/8-border.png');
            files.mouth.fill = require('./assets/mouth/8-fill.png');
            break;
        case 9:
            files.mouth.border = require('./assets/mouth/9-border.png');
            files.mouth.fill = require('./assets/mouth/9-fill.png');
            break;
        case 10:
            files.mouth.border = require('./assets/mouth/10-border.png');
            files.mouth.fill = require('./assets/mouth/10-fill.png');
            break;
        case 11:
            files.mouth.border = require('./assets/mouth/11-border.png');
            files.mouth.fill = require('./assets/mouth/11-fill.png');
            break;
        case 12:
            files.mouth.border = require('./assets/mouth/12-border.png');
            files.mouth.fill = require('./assets/mouth/12-fill.png');
            break;
        case 13:
            files.mouth.border = require('./assets/mouth/13-border.png');
            files.mouth.fill = require('./assets/mouth/13-fill.png');
            break;
        case 14:
            files.mouth.border = require('./assets/mouth/14-border.png');
            files.mouth.fill = require('./assets/mouth/14-fill.png');
            break;
    }

    return (
        <>
            <div className="container-gpt mx-auto">
                <img
                    src={files.body.fill}
                    alt=""
                    style={{
                        zIndex: '994',
                        filter: filter[characterTab].body as string,
                    }}
                />
                <img src={files.body.border} alt="" style={{zIndex: '995'}} />
                <img
                    src={require('./assets/eyes/fill.png')}
                    alt=""
                    style={{
                        zIndex: '998',
                        filter: filter[characterTab].eyes as string,
                    }}
                />
                <img
                    src={require('./assets/eyes/border.png')}
                    alt=""
                    style={{zIndex: '998'}}
                />
                <img
                    src={require('./assets/head/fill.png')}
                    alt=""
                    style={{
                        zIndex: '997',
                        filter: filter[characterTab].body as string,
                    }}
                />
                <img
                    src={require('./assets/head/border.png')}
                    alt=""
                    style={{zIndex: '997'}}
                />
                <img
                    src={files.hair.fill}
                    alt=""
                    style={{
                        zIndex: '998',
                        filter: filter[characterTab].hair as string,
                    }}
                />
                <img src={files.hair.border} alt="" style={{zIndex: '998'}} />
                <img
                    src={files.mouth.fill}
                    alt=""
                    style={
                        {
                            zIndex: '997',
                            filter: filter[characterTab].mouth as string,
                        }
                } />
                <img src={files.mouth.border} alt="" style={{zIndex: '997'}} />
                <img
                    src={files.top.fill}
                    alt=""
                    style={
                        {
                            zIndex: '996',
                            filter: filter[characterTab].top as string,
                        }
                    }
                />
                <img
                    src={files.top.border}
                    alt=""
                    style={{zIndex: '996'}}
                />
                <img
                    src={files.bottom.fill}
                    alt=""
                    style={
                        {
                            zIndex: '995',
                            filter: filter[characterTab].bottom as string,
                        }
                    }
                />
                <img
                    src={files.bottom.border}
                    alt=""
                    style={{zIndex: '995'}}
                />
            </div>
        </>
    );
}

function CustomInput({
                         id,
                         col = '7',
                         width = '80',
                         enter = '',
                         type = 'text',
                     }: {
    id: string;
    width?: string;
    col?: string;
    enter?: string;
    type?: string;
}) {
    return (
        <>
            { enter ?? <label htmlFor={id}>Enter {enter}</label> }
            {/*<input id={id} name={id} type={type} className="col-span-9 mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"/>*/}
            <input required id={id} name={id} type={type} className={`col-span-${col} block w-${width} rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-pink-400 sm:text-sm sm:leading-6`}/>
        </>
    );
}

export default ContactForm;
